import 'element-internals-polyfill'
import '../polyfills/scroll-into-view-if-needed'

import MultiSelect from './multi-select'
import AutoComplete from './auto-complete'
import DatePicker from './date-picker'
import TagSelector from './tag-selector'
import ItemSorter from './item-sorter'
import UploadFiles from './upload-files'
import DateRangePicker from './date-range-picker'
import MonoSelect from './mono-select'
import ScrollToTop from './scroll-to-top'


customElements.define('multi-select', MultiSelect)
customElements.define('mono-select', MonoSelect)
customElements.define('auto-complete', AutoComplete)
customElements.define('date-picker', DatePicker)
customElements.define('tag-selector', TagSelector)
customElements.define('item-sorter', ItemSorter)
customElements.define('upload-files', UploadFiles)
customElements.define('date-range-picker', DateRangePicker)
customElements.define('scroll-to-top', ScrollToTop)
