// @ts-check
import {LitElement, html} from 'lit'
import {createRef, ref} from 'lit/directives/ref.js'
import config from '../config'
import measure from '../measure'
import events from '../events'
import options from '../options'
import scrollIntoView from 'scroll-into-view-if-needed'


/**
 * @typedef {import('../options').JSONOption} JSONOption
 * @typedef {import('../options').JSONOptGroup} JSONOptGroup
 */

export default class MultiSelect extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      placeholder: {type: String},
      name: {type: String},
      dropdownVisible: {type: Boolean, attribute: false},
      selectedItems: {attribute: false},
      regexp: {type: Object, attribute: false},
      dropdownInfo: {attribute: false},
      index: {type: Number, attribute: false},
      slottedValues: {type: Array, attribute: false},
      visibleItems: {type: Array, attribute: false},
      class: {type: String},
      isLoading: {type: Boolean},
    }
  }

  constructor() {
    super()
    this._internals = this.attachInternals()

    this.dropdownVisible = false
    /**
     * @type {{
     *  classPosition?: string,
     *  availableSpace?: number,
     * }}
     */
    this.dropdownInfo = {}

    /** @type {Set<JSONOption>} */
    this.selectedItems = new Set()

    this.regexp = /./
    this.placeholder = ''

    /** @type {string[]} */
    this.pastValue = []

    /** @type {number?} */
    this.index = null

    /** @type {(JSONOption|JSONOptGroup)[]} */
    this.slottedValues = []

    /** @type {(JSONOption | JSONOptGroup)[]} */
    this.visibleItems = []

    /** @type {import('lit/directives/ref').Ref<HTMLInputElement>} */
    this.$query = createRef()

    /** @type {boolean} */
    this.isLoading = false
  }

  // register outside click listener
  connectedCallback() {
    super.connectedCallback()

    // register slotchange listener
    this._slotListener = () => this._onSlotChange()
    this.shadowRoot.addEventListener('slotchange', this._slotListener)

    // close popup on click outside
    this._listener = event => {
      const isClicked = event.composedPath().includes(this)
      if (!isClicked) {
        this.dropdownVisible = false
      }
    }

    document.addEventListener('click', this._listener)
  }

  _onSlotChange() {
    const $slot = /** @type {HTMLSlotElement} */ (this.renderRoot.querySelector('slot'))
    if (!$slot) {
      console.trace('_onSlotChange called before slot was available')
      return
    }
    const elements = $slot.assignedElements()
    const parsed = options.parseSlottedOptions(elements)
    this.slottedValues = parsed.elements
    this.selectedItems = parsed.selected

    this.pastValue = this.value

  }

  // remove outside click listener
  disconnectedCallback() {
    super.disconnectedCallback()
    this.shadowRoot.removeEventListener('slotchange', this._slotListener)
    document.removeEventListener('click', this._listener)
  }


  /**
   * Toggle one or more keys
   * @param {...JSONOption} keys
   */
  _toggle(...keys) {
    const toggled = new Set(keys.map(k => k.value))
    const selected = new Set(Array.from(this.selectedItems).map(i => i.value))
    const allSelected = keys.map(k => k.value).every(k => selected.has(k))

    // if all are selected, remove keys from new selected items
    // otherwise add keys to selected items
    const newValues = (
      allSelected
        ? [...this.selectedItems].filter(i => !toggled.has(i.value))
        : [...this.selectedItems].concat(keys)
    )
    this.selectedItems = new Set(newValues)
  }


  updated(changedProps) {
    const name = this.getAttribute('name')
    if (name && changedProps.has('selectedItems')) {
      const submitData = new FormData()
      for (const key of this.selectedItems) {
        submitData.append(name, key.value)
      }
      this._internals.setFormValue(submitData)
    }

    if (changedProps.has('dropdownVisible')) {
      if (this.dropdownVisible) {
        // dropdown opened
        this.$query.value?.focus()
        this._updateVisibleItems(this.$query.value?.value || '')

        // reset index for keyboard selection
        this.index = null

        const $trigger = /** @type {HTMLDivElement} */ (this.renderRoot.querySelector('.dropdown-trigger'))
        const $dropdown = /** @type {HTMLDivElement} */ (this.renderRoot.querySelector('.dropdown-content'))
        const $search = /** @type {HTMLDivElement} */ (this.renderRoot.querySelector('#search-bar'))
        const measures = measure.getAvailableSpace($trigger)

        if (measures.below >= Math.max($dropdown.offsetHeight / 2, 200)) {
          this.dropdownInfo = {
            'classPosition': 'is-below',
            'availableSpace': measures.below - $search.offsetHeight - parseInt($dropdown.style.paddingTop || '0'),
          }
        } else {
          this.dropdownInfo = {
            classPosition: 'is-up',
            availableSpace: measures.above - $search.offsetHeight - parseInt($dropdown.style.paddingTop || '0'),
          }
        }

      } else {
        // dropdown closed
        if (this.value.join(', ') !== this.pastValue.join(', ')) {
          events.trigger('change', this)
          this.pastValue = this.value
        }
        this.dropdownInfo = {
          'classPosition': 'is-below',
          'availableSpace': 200,
        }
      }
    }


  }

  _toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
  }

  _onKeyup(/** @type {KeyboardEvent} */ event) {
    const $target = /** @type {HTMLInputElement} */ (event.target)
    this._updateVisibleItems($target.value)
    const values = this.visibleItems.flatMap(e => options.isJSONOptGroup(e) ? [e, ...e.options] : e)

    events.handleIndexKeyboardEvent(
      event, this.index, values.length - 1,
      {
        setIndex: (value) => {
          this.index = value
          const $activeItem = this.renderRoot.querySelector('.dropdown-item.is-active')
          if ($activeItem) {
            scrollIntoView($activeItem, {
              scrollMode: 'if-needed',
              block: 'nearest',
              inline: 'nearest',
            })
          }
        },
        onBlur: () => { this.dropdownVisible = false },
        onSelect: (index) => {
          const elem = values[index]
          if (options.isJSONOptGroup(elem)) {
            this._toggle(...elem.options.filter(options.isJSONOption))
          } else {
            this._toggle(elem)
          }
        },
      },
    )
  }


  /**
   * Toggle all visible elements
   * @returns {void}
   */
  _toggleAllVisible() {
    this._toggle(...Array.from(options.iterOptions(this.visibleItems)))
  }

  /**
   * @returns {string[]}
   */
  get value() {
    return Array.from(this.selectedItems).map(i => i.value).sort()
  }

  get label() {
    return Array.from(this.selectedItems).map(i => i.text).sort().join(', ')
  }

  render() {
    const classAttr = this.getAttribute('class') ?? ''
    const className = `${classAttr} input is-clickable has-text-ellipsed is-fullwidth`

    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <slot style="display: none"></slot>

      <div class="dropdown is-flex-grow-1 ${this.dropdownVisible ? 'is-active' : ''} ${this.dropdownInfo.classPosition}" >
        <div class="dropdown-trigger is-flex is-flex-grow-1" @click="${this._toggleDropdown}">
          <div class="control is-flex is-flex-grow-1">
            <div class="control has-icons-right is-flex is-flex-grow-1">
              <input
                  class="${className}"
                  type="text"
                  .value=${this.label}
                  tabindex="${this.dropdownVisible ? -1 : 0}"
                  .placeholder=${this.placeholder}
                  readonly>
              <span class="icon is-right has-text-link">
                <i class="fa fa-bars"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="dropdown-menu" role="menu" >
          <div class="dropdown-content py-0">
            <div class="dropdown-item p-2" id="search-bar">
              <div class="field">
                <div class="control has-icons-right is-flex is-flex-grow-1 ${ this.isLoading ? 'is-loading' : '' }">
                  <input class="input" ${ref(this.$query)} role="search" type="text" @keyup=${this._onKeyup} placeholder="Search">
                  ${ this.isLoading ? '' : html`
                    <span class="icon is-right">
                      <i class="fas fa fa-search"></i>
                    </span>
                  `}
                </div>
              </div>
            </div>
            <div class="dropdown-divider mt-0"></div>
            <a class="dropdown-item has-background-white-ter is-size-7 is-underlined" data-testid="toggle-all" @click=${this._toggleAllVisible}>
                Seleziona tutto
            </a>
            <div style="overflow-y: scroll; max-height: ${this.dropdownInfo.availableSpace}px" id="items">
              ${this.dropdownVisible
        ? this.visibleItems
          .flatMap(g => options.isJSONOption(g) ? g : [g, ...g.options])
          .map((e, idx) => this._renderSlotTemplate(e, idx))
        : html``
      }
            </div>
          </div>
        </div>
      </div>
    `
  }

  /**
   * @param {(JSONOption|JSONOptGroup)} item
   * @param {number} idx
   */
  _renderSlotTemplate(item, idx) {
    const selectedClass = idx === this.index ? 'is-active' : ''

    if (options.isJSONOptGroup(item)) {
      return html`
        <a class="dropdown-item has-text-ellipsed has-text-weight-semibold ${selectedClass}" 
           title=${item.label}
           @click=${() => this._toggle(...item.options.filter(options.isJSONOption))}
           data-testid="optgroup">
          ${item.label}
        </a>
      `
    }

    const textColor = idx === this.index ? '' : 'has-text-link'
    return html`
      <label>
        <a class="dropdown-item has-text-ellipsed ${selectedClass}" title=${item.text} @click=${() => this._toggle(item)} data-testid="option">
          <span class="icon is-small ${textColor}">
            ${this._isSelected(item)
        ? html`<i class="fas fa-square"></i>`
        : html`<i class="far fa-square"></i>`
      }
          </span>
          ${item.text}
        </a>
      </label>
    `
  }


  /**
   * @param {JSONOption} item
   * @returns {boolean}
   */
  _isSelected(item) {
    return Array.from(this.selectedItems).map(s => s.value).includes(item.value)
  }
  /**
   * @param {string} q
   */
  _updateVisibleItems(q) {
    this.attributes.getNamedItem
    const href = this.getAttribute('href')
    if (!href) {
      this.visibleItems = options.filterParsedOptions(this.slottedValues, q)
    } else {
      const url = new URL(href, document.location.toString())
      url.searchParams.set('query', q)

      this.isLoading = true
      fetch(url.toString())
        .then(async result =>  {
          this.visibleItems = options.normalizeOptOrGroup(await result.json())
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
