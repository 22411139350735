import {LitElement, html} from 'lit'
import config from '../config'
import {classMap} from 'lit/directives/class-map.js'


function isVisible() {
  return window.scrollY > 150
}

export default class ScrollToTop extends LitElement {
  constructor() {
    super()
    this.visible = isVisible()
  }

  static get properties() {
    return {
      visible: {attribute: false, type: Boolean},
    }
  }


  connectedCallback() {
    super.connectedCallback()
    window.addEventListener('scroll', () => {
      this.visible = isVisible()
    })
  }

  render() {
    const itemClass = classMap({
      'button': true,
      'is-hidden': !this.visible,
    })
    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>

        <a href="#" class=${itemClass} style="position: fixed; right: 2em; bottom: 2em; z-index: 100">
        <i class="fa fa-arrow-up"></i>
      </a>`
  }
}
