import {LitElement, html, css} from 'lit'
import {classMap} from 'lit/directives/class-map.js'
import measure from '../measure'
import events from '../events'
import config from '../config'


export default class ItemSorter extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      placeholder: {type: String},
      name: {type: String},
      dropdownVisible: {type: Boolean, attribute: false},
      filterValue: {type: Object, attribute: false},
      availableItems: {attribute: false},
      fromAvailable: {attribute: false},
      sortedItems: {attribute: false},
      index: {attribute: false},
      dropdownInfo: {attribute: false},
      class: {type: String},
    }
  }

  constructor() {
    super()
    this._internals = this.attachInternals()
    this.dropdownVisible = false
    this.availableItems = []
    this.sortedItems = []
    this.index = null
    this.dropdownInfo = {}
    this.dragged = null
    this.fromAvailable = false
    this.filterValue = new RegExp('', 'i')
    this.repr = ''
  }

  static styles = css`
    div.dropdown-item.is-draggable {
      border: 1px solid transparent;
    }

    div.dropdown-item.is-draggable:hover {
      border: 1px solid hsl(0, 0%, 86%);
      cursor: grab !important;
    }

    .scroll-y {
      overflow-y: scroll;
      min-width: 12rem;
    }

    .is-clipped {
      overflow: hidden;
    }

    .available-items {
      border-left: 3px solid #3e56c4;
    }
  `

  // register outside click listener
  connectedCallback() {
    super.connectedCallback()
    this._listener = document.addEventListener('click', event => {
      const isClicked = event.composedPath().includes(this)
      if (!isClicked) {
        this.toggleDropdown('close')
      }
    })

    this.slotListener = () => this._onSlotChange()
    this.shadowRoot.addEventListener('slotchange', this.slotListener)
    this.shadowRoot.addEventListener('dragend', () => {
      this.index = null
      this.dragged = null
      this.fromAvailable = false
    })
  }

  // remove outside click listener
  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener('click', this._listener)
    this.shadowRoot.addEventListener('slotchange', this.slotListener)
  }

  _onSlotChange() {
    this.readSlottedValues()
    this.updateForm()
  }

  updated(changedProps) {
    if (changedProps.has('dropdownVisible')) {
      if (!changedProps.get('dropdownVisible')) {
        // dropdown opened
        const elem = this.renderRoot.querySelector('input[role="search"]')
        elem.focus()
      }
    }
  }

  updateForm() {
    const submitData = new FormData()
    for (const item of this.sortedItems) {
      submitData.append(this.name, item.value)
    }
    this._internals.setFormValue(submitData)
  }

  readSlottedValues() {
    const slot = this.renderRoot.querySelector('slot')
    if (slot) {
      const elements = slot.assignedElements()
      const items = []
      const sorted = []

      for (const elem of elements) {
        if (elem.tagName === 'OPTION') {
          const label = elem.text
          const value = elem.value ?? label

          if (elem.selected) {
            sorted.push({value, label})
          } else {
            items.push({value, label})
          }
        }
      }

      this.availableItems = items
      this.sortedItems = sorted
      this.repr = this.sortedItems.map(e => e.value).join('')
    }
  }

  toggleDropdown(action) {
    const measures = measure.getAvailableSpace(this.shadowRoot.querySelector('.dropdown'))
    this.dropdownInfo = {
      classPosition: measures.below < measures.above ? 'is-up' : '',
      availableSpace: Math.max(measures.below, measures.above),
    }
    if (this.dropdownVisible || action === 'close') {
      this.dropdownVisible = false
      const repr = this.sortedItems.map(e => e.value).join('')
      if (this.repr !== repr) {
        this.repr = repr
        events.trigger('change', this)
      }
    } else {
      this.dropdownVisible = true
    }
  }

  onKeyup(event) {
    if (event.key === 'Escape') {this.toggleDropdown()}
    this.filterValue = new RegExp(event.target.value, 'i')
  }

  dropIn() {
    const sorted = []
    let pushed = false
    const others = this.sortedItems.filter(item => item !== this.dragged)
    others.map((elem, index) => {
      if (this.index === index) {
        sorted.push(this.dragged)
        pushed = true
      }
      sorted.push(elem)
    })

    if (!pushed) {
      sorted.push(this.dragged)
    }

    this.sortedItems = sorted
    this.availableItems = this.availableItems.filter(item => item !== this.dragged)
    this.updateForm()
  }


  dropOut() {
    this.sortedItems = this.sortedItems.filter(item => item !== this.dragged)
    if (!this.availableItems.includes(this.dragged)) {
      this.availableItems = [this.dragged, ...this.availableItems]
    }
    this.index = null
    this.fromAvailable = false
    this.updateForm()
  }

  get value() {
    return this.sortedItems.map(({label}) => label).join(', ')
  }


  render() {
    const className = `${this.class ?? '' } input is-clickable has-text-ellipsed is-fullwidth`
    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <slot style="display: none"></slot>
      <div class="is-flex-grow-1 dropdown ${this.dropdownVisible ? 'is-active' : ''} ${this.dropdownInfo.classPosition}">
        <div class="dropdown-trigger is-flex is-flex-grow-1" @click=${this.toggleDropdown}>
          <div class="control has-icons-right is-flex is-flex-grow-1">
            <input
                class="${className}"
                type="text"
                .value=${this.value}
                tabindex="${this.dropdownVisible ? -1 : 0}"
                .placeholder=${this.placeholder}
                readonly>
            <span class="icon is-right has-text-link">
              <i class="fas fa-sort-alpha-down"></i>
            </span>
          </div>
        </div>
        <div class="dropdown-menu">
          ${this.renderSearch()}
        </div>
      </div>
    `
  }

  renderSearch() {
    const elemStyle = {
      'column': true,
      'scroll-y': true,
      'is-flex-direction-column': true,
      'is-flex': true,
      'px-2': true,
      'has-background-info-light': !this.fromAvailable && this.index === -1,
      'has-background-light': this.fromAvailable || this.index !== -1,
    }

    return html`
      <div class="dropdown-content is-paddingless">
        <div class="columns is-marginless is-mobile" style="width: max-content; max-height: ${this.dropdownInfo.availableSpace}px">
          <div class="column scroll-y is-flex-direction-column is-flex">
            ${this.sortedItems.map((item, index) => this.renderSorted(item, index))}
            ${this.renderSortedPlaceholder(this.sortedItems.length)}
          </div>
          <div class=${classMap(elemStyle)}>
            <div class="dropdown-item">
              <div class="field has-addons">
                <div class="control has-icons-right">
                  <input 
                    role="search"
                    type="text" class="input is-small is-fullwidth"
                    placeholder=${this.placeholder}
                    @keyup=${evt => this.onKeyup(evt)}
                  />
                  <span class="icon is-small is-right">
                    <i class="fas fa fa-search"></i>
                  </span>
                </div>
              </div>
            </div>
            ${this.availableItems.filter(item => this.filterValue.test(item.label)).map(item => this.renderAvailable(item))}
            ${this.renderAvailablePlaceholder()}
          </div>
        </div>
      </div>
      `
  }


  renderAvailable(item) {
    const dragStart = () => {
      this.dragged = item
      this.fromAvailable = true
    }

    const dragEnter = () => {this.index = -1}
    const dragOver = event => event.preventDefault()
    const drop = () =>  this.dropOut()
    const elemStyle = {
      'dropdown-item': true,
      'is-draggable': true,
      'has-background-info-light': !this.fromAvailable && this.index === -1,
    }

    return html`
        <div 
            @dragstart=${dragStart}
            @dragover=${dragOver}
            @dragenter=${dragEnter}
            @drop=${drop}
            tabindex="-1"
            draggable="true"
            class=${classMap(elemStyle)} 
        >
          ${item.label}
        </div>
      `
  }

  renderAvailablePlaceholder() {
    const dragEnter = () => {this.index = -1}
    const dragOver = event => event.preventDefault()
    const drop = () =>  this.dropOut()
    const elemStyle = {
      'dropdown-item': true,
      'is-flex-grow-2': true,
      'has-background-info-light': !this.fromAvailable && this.index === -1,
    }

    return html`
        <div 
            @dragover=${dragOver}
            @dragenter=${dragEnter}
            @drop=${drop}
            tabindex="-1"
            class=${classMap(elemStyle)} 
        >
        </div>
      `
  }



  renderSortedPlaceholder(index) {
    const dragEnter = () => {this.index = index}
    const dragOver = event => event.preventDefault()
    const drop = () => this.dropIn()
    const elemStyle = {
      'dropdown-item': true,
      'has-background-info-light': index === this.index,
      'is-flex-grow-2': true,
    }

    return html`
      <div 
          @dragenter=${dragEnter}
          @dragover=${dragOver}
          @drop=${drop}
          tabindex="-1"
          data-testid="add-value"
          class=${classMap(elemStyle)} >
          &nbsp;
      </div>
    `
  }



  renderSorted(item, index) {
    const dragEnter = () => {this.index = index}
    const dragOver = event => event.preventDefault()
    const dragStart = () => {this.dragged = item}
    const drop = () => this.dropIn()
    const elemStyle = {
      'dropdown-item': true,
      'is-draggable': true,
      'has-background-info-light': index === this.index,
    }

    return html`
      <div 
          @dragenter=${dragEnter}
          @dragover=${dragOver}
          @drop=${drop}
          @dragstart=${dragStart}
          tabindex="-1"
          data-testid="add-value"
          draggable="true"
          class=${classMap(elemStyle)} >
          ${item.label}
      </div>
    `
  }

}

