import {LitElement, html} from 'lit'
import config from '../config'
import {ref, createRef} from 'lit/directives/ref.js'
import {classMap} from 'lit/directives/class-map.js'
import {ifDefined} from 'lit/directives/if-defined.js'


export function acceptFilter(accept) {
  if (!accept) {
    return () => true
  }

  const accepts = accept.split(',').map(s => s.trim().replace('*', '.*'))
  const regexes = accepts.map(a => new RegExp(a))

  return (type) => regexes.some(r => r.test(type))
}

export default class UploadFiles extends LitElement {
  static formAssociated = true
  $fileInput = createRef()

  static get properties() {
    return {
      name: {attribute: true, type: String},
      label: {attribute: true, type: String},
      class: {type: String},
      accept: {attribute: true, type: String},
      _files: {attribute: false, type: Array},
      readonly: {attribute: true, type: Boolean},
      disabled: {attribute: true, type: Boolean},
      download: {attribute: true, type: Boolean},
    }
  }

  get modifiable() {
    return !(this.readonly || this.disabled)
  }

  constructor() {
    super()
    this._internals = this.attachInternals()
    this._files = []

    this._listeners = {
      drop: (e) => this._onDrop(e),
      dragover: (e) => this._onDragOver(e),
      slotchange: (e) => this._setValueFromSlots(e),
    }

    this.acceptFilter = acceptFilter(this.accept)
    this.label = 'Carica o trascina uno o più file'
    this.readonly = false
    this.disabled = false
  }

  connectedCallback() {
    super.connectedCallback()
    this.addEventListener('dragover', this._listeners.dragover)
    this.addEventListener('drop', this._listeners.drop)
    this.shadowRoot.addEventListener('slotchange', this._listeners.slotchange)
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener('dragover', this._listeners.dragover)
    this.removeEventListener('drop', this._listeners.drop)
    this.shadowRoot.removeEventListener('slotchange', this._listeners.slotchange)
  }
  updated(changedProps) {
    if (this.name && changedProps.has('_files')) {
      const formData = new FormData()
      for (const f of this._files) {
        if (f instanceof File) {
          formData.append(this.name, f, f.name)
        } else {
          formData.append(this.name, f.value)
        }
      }
      if (this._files.length === 0) {
        formData.append(this.name, '')
      }
      this._internals.setFormValue(formData)
    }

    if (changedProps.has('accept')) {
      this.acceptFilter = acceptFilter(this.accept)
    }
  }

  _setValueFromSlots() {
    const slot = this.renderRoot.querySelector('slot')
    const elements = slot.assignedElements().filter(e => e.tagName === 'OPTION')

    this._files = elements.map(e => ({
      name: e.text,
      href: e.firstElementChild?.href,
      value: e.value,
    }))
  }

  _onHiddenInputChange(e) {
    this._addFiles(e.target.files)
  }

  _addFiles([...newFiles]) {
    const hashFile = f => JSON.stringify([
      f.name, f.lastModified, f.type, f.size,
    ])

    const files = new Map()
    for (const f of this._files) {
      files.set(hashFile(f), f)
    }

    const filteredNewFiles = newFiles.filter(f => this.acceptFilter(f.type))
    for (const f of filteredNewFiles) {
      files.set(hashFile(f), f)
    }

    const filesArray = [...files.values()]
    this._files = filesArray
  }

  _onDrop(e) {
    e.preventDefault()
    if (this.modifiable) {
      this._addFiles(e.dataTransfer.files)
    }
  }

  _onDragOver(e) {
    e.preventDefault()
  }

  _onTrashClick(e, f) {
    e.preventDefault()
    e.stopPropagation()
    this._files = this._files.filter(file => file !== f)
  }

  _previewFile(f) {
    const url = window.URL.createObjectURL(f)
    window.open(url, '_blank')
  }

  render() {
    const className = `${this.class ?? ''}`
    return html`
      <style>
        .circular-progress {
          width: .9rem;
          height: .9rem;
          transform: rotate(-90deg);
          display: inline;
        }
        .circular-progress__path {
          cx: 50px;
          cy: 50px;
          r: 40px;
          stroke-width: 15px;
          stroke: lightgray;
          fill: transparent;
        }

        .circular-progress__stroke {
          stroke: gray;
          stroke-dasharray: 251.32;
        }

        .panel-block-header {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        .panel-container {
          border: 1px solid lightgray;
        }

        .panel-block:not([disabled]):hover .hide-on-hover {
          display: none;
        }
        .panel-block:not([disabled]):not(:hover) .show-on-hover {
          display: none;
        }
        .panel-block[disabled] .show-on-hover {
          display: none;
        }
      </style>

      <input
        ${ref(this.$fileInput)}
        @change=${(e) => this._onHiddenInputChange(e)}
        type="file" class="is-hidden" multiple=""
        accept=${ifDefined(this.accept)}>

      <slot class="is-hidden"></slot>
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>

      <div class="panel is-shadowless panel-container ${className}">

      ${this._renderHeader()}

      ${ this._files.map(f => (f instanceof File) ? this._renderFile(f): this._renderOption(f)) }
    </div>
    `
  }

  _renderHeader() {
    if (!this.modifiable) {
      if (this._files.length) {
        return html``
      }

      return html`
        <span class="panel-block panel-block-header has-background-white-bis" style="cursor: not-allowed">
        <span class="panel-icon">
          <i class="fa-solid fa-file-circle-xmark"></i>
        </span>
        <span class="has-text-ellipsed"> Nessun file presente</span>
      </span>
      `
    }
    return html`
      <a class="panel-block panel-block-header has-background-white-bis" @click=${() => this.$fileInput.value.click()}>
        <span class="panel-icon">
          <i class="fas fa-cloud-upload-alt"></i>
        </span>
        <span class="has-text-ellipsed"> ${this.label} </span>
      </a>
    `
  }

  // file appena caricato
  _renderFile(f) {
    return html`
      <a class="panel-block look-at-me-success" @click=${() => this._previewFile(f)}>
        <span class="panel-icon">
          <span class="hide-on-hover">
            <i class="fa-solid fa-check"></i>
          </span>
          <span class="show-on-hover">
            <i class="fa fa-trash has-text-danger is-clickable"
              data-testid="delete-item"
              @click=${(e) => this._onTrashClick(e, f)}></i>
          </span>
        </span>
        <span class="has-text-ellipsed"> ${f.name} </span>
      </a>
    `
  }


  _renderOption(f) {
    const iconClass = classMap({
      'fa-solid': true,
      'fa-check': this.modifiable,
      'fa-file': !this.modifiable,
    })
    const content = html`
      <span class="panel-icon">
        <span class="hide-on-hover">
          <i class="${iconClass}"></i>
        </span>
        <span class="show-on-hover">
          <i class="fa fa-trash has-text-danger is-clickable"
            @click=${(e) => this._onTrashClick(e, f)}
            data-testid="delete-item"></i>

        </span>
      </span>
      <span class="has-text-ellipsed"> ${f.name} </span>
    `

    if (f.href) {
      return html`<a class="panel-block" .href=${f.href} target="_blank" download=${ifDefined(this.download ? f.name : undefined)} ?disabled=${!this.modifiable}>${content}</a>`
    }
    return html`<p class="panel-block" ?disabled=${!this.modifiable}>${content}</p>`
  }
}
