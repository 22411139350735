// @ts-check
import {LitElement, html} from 'lit'
import {classMap} from 'lit/directives/class-map.js'
import config from '../config'
import measure from '../measure'
import events from '../events'
import {ref, createRef} from 'lit/directives/ref.js'
import scrollIntoView from 'scroll-into-view-if-needed'
import options from '../options'

/**
 * @typedef {import('../options').JSONOption} JSONOption
 * @typedef {import('../options').JSONOptGroup} JSONOptGroup
 */


export default class MonoSelect extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      placeholder: {type: String},
      name: {type: String},
      dropdownVisible: {type: Boolean, attribute: false},
      selectedItem: {attribute: false},
      dropdownInfo: {attribute: false},
      index: {type: Number, attribute: false},
      slottedValues: {type: Array, attribute: false},
      visibleItems: {type: Array, attribute: false},
      class: {type: String},
      href: {type: String},
      isLoading: {type: Boolean},
    }
  }

  constructor() {
    super()
    this._internals = this.attachInternals()

    /** @type {boolean} */
    this.dropdownVisible = false

    /** @type {{classPosition: string, availableSpace: number}} */
    this.dropdownInfo = {
      classPosition: 'is-down',
      availableSpace: 0,
    }

    /**
     * Item selected by the click of the user.
     * @type {JSONOption?}
     */
    this.selectedItem = null

    this.placeholder = /** @type {string} */ this.getAttribute('placeholder') ?? ''
    this.pastValue = ''
    this.initialItem = this.selectedItem

    /**
     * Item selected by the arrow keys
     * @type {number?}
     */
    this.index = null

    /**
     * List of elements parsed in <slot/>
     * @type {(JSONOption | JSONOptGroup)[]}
     */
    this.slottedValues = []

    /**
     * List of element rendered in the dropdown
     * @type {(JSONOption | JSONOptGroup)[]}
     */
    this.visibleItems = []

    /**
     * Component href attribute
     * @type {string?}
     */
    this.href = this.getAttribute('href') ?? ''

    /**
     * Reference to the input filter field
     * @type {import("lit/directives/ref").Ref<HTMLInputElement>}
     */
    this.$query = createRef()

    /**
     * @type {Object.<string, function(any):void >}
     */
    this._listeners = {}

    /** @type {boolean} */
    this.isLoading = false
  }

  connectedCallback() {
    super.connectedCallback()

    // register slotchange listener
    this._listeners.onslotchange = () => this._onSlotChange()

    this.$root = /** @type {ShadowRoot} */ (this.shadowRoot)
    this.$root.addEventListener('slotchange', this._listeners.onslotchange)

    /**
     * Close popup on click outside
     * @type {function(MouseEvent):void}
     */
    this._listeners.onclick = event => {
      const isClicked = event.composedPath().includes(this)
      if (!isClicked) {

        this.dropdownVisible = false
      }
    }

    document.addEventListener('click', this._listeners.onclick)
  }

  _onSlotChange() {
    const $slot = this.renderRoot.querySelector('slot')
    if (!$slot) {
      console.trace('_onSlotChange called but no slot was found')
      return
    }
    const elements = $slot.assignedElements()

    const parsed = options.parseSlottedOptions(elements)
    this.slottedValues = parsed.elements
    this.selectedItem = parsed.selected.values().next().value

    this.visibleItems = []

    this.pastValue = this.value
    this.initialItem = this.selectedItem
  }

  // remove outside click listener
  disconnectedCallback() {
    super.disconnectedCallback()
    this.$root?.removeEventListener('slotchange', this._listeners.onslotchange)
    document.removeEventListener('click', this._listeners.onclick)
  }


  /**
   * @param {JSONOption} item
   */
  _selectItem(item) {
    this.selectedItem = this.selectedItem?.value === item.value ? null : item
    this.$query.value?.focus()
    this.dropdownVisible = false
  }


  updated(changedProps) {
    if (this.getAttribute('name') && changedProps.has('selectedItem')) {
      this._internals.setFormValue(this.selectedItem?.value)
    }
    if (changedProps.has('dropdownVisible')) {
      if (this.dropdownVisible) {
        // dropdown opened
        this.$query.value?.focus()

        this._updateVisibleItems('')

        // reset index for keyboard selection
        this.index = null

        const $trigger = this.renderRoot.querySelector('.dropdown-trigger')
        const $dropdown = /** @type {HTMLDivElement} */ (this.renderRoot.querySelector('.dropdown-content'))
        const $search = /** @type {HTMLInputElement} */ (this.renderRoot.querySelector('#search-bar'))
        const measures = measure.getAvailableSpace($trigger)

        if (measures.below >= Math.max($dropdown.offsetHeight / 2, 200)) {
          this.dropdownInfo = {
            'classPosition': 'is-below',
            'availableSpace': measures.below - $search.offsetHeight - parseInt($dropdown.style.paddingTop || '0'),
          }
        } else {
          this.dropdownInfo = {
            classPosition: 'is-up',
            availableSpace: measures.above - $search.offsetHeight - parseInt($dropdown.style.paddingTop || '0'),
          }
        }
      } else {
        // dropdown closed
        if (this.value !== this.pastValue) {
          events.trigger('change', this)
          this.pastValue = this.value
        }
        if (this.$query.value) {
          this.$query.value.value = ''
        }
      }
    }

    if (changedProps.has('index')) {
      const $itemSelected = this.renderRoot.querySelector('.dropdown-item.is-active')
      if ($itemSelected) {
        scrollIntoView($itemSelected, {
          scrollMode: 'if-needed',
          block: 'nearest',
          inline: 'nearest',
        })
      }
    }

  }

  formResetCallback() {
    this.selectedItem = this.initialItem
  }

  _toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
  }

  /**
   * @param {KeyboardEvent} event
   */
  _onKeyup(event) {

    const $target = /** @type {HTMLInputElement} */ (event.target)
    const query = $target.value

    this._updateVisibleItems(query)

    const values = this.visibleItems.flatMap(elem => {
      if (options.isJSONOptGroup(elem)) {
        return elem.options
      }
      return elem
    })

    events.handleIndexKeyboardEvent(
      event, this.index, values.length -1,
      {
        setIndex: (value) => {
          this.index = value
        },
        onBlur: () => {this.dropdownVisible = false},
        onSelect: (index) => {
          const elem = values[index]
          if (options.isJSONOption(elem)) {
            this._selectItem(elem)
          }
        },
      },
    )

  }

  get value() {
    return this.selectedItem?.value || ''
  }

  render() {
    const className = `${this.getAttribute('class') ?? '' } input is-clickable has-text-ellipsed is-fullwidth`

    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <slot style="display: none"></slot>

      <div class="dropdown is-flex-grow-1 ${this.dropdownVisible ? 'is-active' : ''} ${this.dropdownInfo.classPosition}" >
        <div class="dropdown-trigger is-flex is-flex-grow-1" @click="${this._toggleDropdown}">
          <div class="control is-flex is-flex-grow-1">
            <div class="control has-icons-right is-flex is-flex-grow-1">
              <input
                  class="${className}"
                  type="text"
                  .value=${this.selectedItem?.text || ''}
                  tabindex="${this.dropdownVisible ? -1 : 0}"
                  .placeholder=${this.placeholder}
                  readonly>
              <span class="icon is-right has-text-link">
                <i class="fas fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="dropdown-menu" role="menu" >
          <div class="dropdown-content py-0">

            ${ this.dropdownInfo.classPosition !== 'is-up' ? this._renderInputQuery() : ''}

            <div style="overflow-y: scroll; max-height: ${this.dropdownInfo.availableSpace}px" id="items" class="py-1">
              ${
                this.dropdownVisible
                  ? this._renderItems()
                  : html``
              }
            </div>

            ${ this.dropdownInfo.classPosition === 'is-up' ? this._renderInputQuery() : ''}
          </div>
        </div>
      </div>
    `
  }

  *_renderItems() {
    let idx = 0
    for (const item of _flatOptions(this.visibleItems)) {
      if (options.isJSONOptGroup(item)) {
        yield html`
            <span class="dropdown-item has-text-ellipsed has-text-weight-bold"
               title=${item.label}>
              ${item.label}
            </span>
          `
        continue
      }

      const selected = this.selectedItem?.value === item.value
      const focused = idx === this.index

      const classes = classMap({
        'dropdown-item': true,
        'has-text-ellipsed': true,
        'is-active': focused,
        'has-background-link-light': selected && !focused,
        'has-text-weight-bold': selected,
      })

      yield html`
          <label>
            <a class="${classes}" title=${item.label} @click=${() => this._selectItem(item)} data-testid="option">${item.html}</a>
          </label>
        `
      idx += 1
    }
  }

  /**
   * Renders the input field for filtering dropdown items
   */
  _renderInputQuery() {
    return html`
        <div class="dropdown-item pt-2 px-2" id="search-bar">
          <div class="field has-addons">
            <div class="control is-flex is-flex-grow-1 has-icons-right ${ this.isLoading ? 'is-loading' : '' }">
            <input class="input" ${ref(this.$query)} role="search" type="text" @keyup=${this._onKeyup} data-testid="filter">
              ${ this.isLoading ? '' : html`
              <span class="icon is-right">
                <i class="fas fa fa-search"></i>
              </span>
              `}
            </div>
          </div>
        </div>
    `
  }

  /**
   * @param {string} query
   */
  _updateVisibleItems(query) {
    if (this.href) {
      const url = new URL(this.href, document.location.toString())
      url.searchParams.append('query', query)

      this.isLoading = true
      fetch(url.toString()).then(
        async response => {
          this.visibleItems = options.normalizeOptOrGroup(await response.json())
        },
      ).catch(error => {
        console.error(error)
      }).finally(() => {
        this.isLoading = false
      })

    } else {
      const reg = new RegExp(query, 'i')
      if (query) {
        this.visibleItems = options.filterParsedOptions(this.slottedValues, reg)
      }
      else {
        this.visibleItems = this.slottedValues
      }
    }
  }
}


/**
 * @param {(JSONOption | JSONOptGroup)[]} items
 * @returns {(JSONOption | JSONOptGroup)[]}
 */
function _flatOptions(items) {
  return items.flatMap(e => options.isJSONOptGroup(e) ? [e, ..._flatOptions(e.options)] : e)
}
