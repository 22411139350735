import {LitElement, html, css} from 'lit'
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import {ref, createRef} from 'lit/directives/ref.js'
import debounce from 'lodash/debounce'
import config from '../config'
import events from '../events'
import measure from '../measure'


export default class AutoComplete extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      placeholder: {type: String},
      href: {type: String},
      name: {type: String},
      class: {type: String},
      dropdownVisible: {type: Boolean, attribute: false},
      items: {attribute: false},
      value: {type: String},
      loading: {type: Boolean, attribute: false},
      initialized: {type: Boolean, attribute: false},
      index: {attribute: false},
      dropdownInfo: {attribute: false},
      accesskey: {type: String},
    }
  }

  constructor() {
    super()
    this._internals = this.attachInternals()
    this.dropdownVisible = false
    this.value = ''
    this.items = []
    this.loading = false,
    this.initialized = false
    this.index = null
    this.dropdownInfo = {}
  }

  static styles = css`
    div.dropdown-item:hover {
      background-color: #f5f5f5;
    }
    div.dropdown-item.is-active {
      background-color: #485fc7;
      color: #fff;
    }
  `

  // register outside click listener
  connectedCallback() {
    super.connectedCallback()
    this._internals.setFormValue(this.value)
    this.inputRef = createRef()

    this._listeners = {
      onpaste: (...params) => this._onPaste(...params),
      onblur: () => {
        if (this.value !== this.hashValue) {
          this.hashValue = this.value
          events.trigger('change', this)
        }
      },
    }

    this.addEventListener('paste', this._listeners.onpaste)


    this._listener = document.addEventListener('click', event => {
      const isClicked = event.composedPath().includes(this)
      if (!isClicked) {
        this.dropdownVisible = false
      }
    })
  }

  _onPaste(e) {
    const val = this.inputRef.value.value
    const start = this.inputRef.value.selectionStart
    const end = this.inputRef.value.selectionEnd

    const pasted = e.clipboardData.getData('text')

    const text = val.slice(0, start) + pasted + val.slice(end, val.length)

    this._setValue(text)
    this.inputRef.value.setSelectionRange(
      start + pasted.length,
      start + pasted.length,
    )
    this._fetchUri(text)
  }

  // remove outside click listener
  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener('click', this._listener)
  }


  firstUpdated(props) {
    super.firstUpdated(props)
    this.inputRef.value.addEventListener('blur', this._listeners.onblur)
  }

  render() {
    const className = `${this.class ?? '' } input has-text-ellipsed is-fullwidth`

    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <div class="is-flex-grow-1 dropdown ${this.dropdownVisible ? 'is-active' : ''} ${this.dropdownInfo.classPosition}">
        <div class="dropdown-trigger is-flex is-flex-grow-1">
          <div class="control has-icons-right is-flex is-flex-grow-1">
            <input role="search"
                ${ref(this.inputRef)}
                autocomplete="off"
                type="text"
                class="${className}"
                placeholder="${this.placeholder}"
                .value="${this.value}" 
                accesskey="${this.accesskey}"
                @click="${this._toggleDropdown}"
                @paste="${e => e.preventDefault()}"
                @keyup="${evt => this._onKeyup(evt)}"
                @keydown="${evt => this._onKeydown(evt)}"
            />
            <span class="icon is-small is-right">
              <i class="${this.loading ? 'fas fa-circle-notch fa-spin' : 'fa fa-search'}"></i>
            </span>
          </div>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content" style="overflow-y: scroll; max-height: ${this.dropdownInfo.availableSpace}px">
            ${this.items.map((item, index) => this._renderItem(item, index))}
          </div>
        </div>
      </div>
    `
  }

  _toggleDropdown() {
    const measures = measure.getAvailableSpace(this.shadowRoot.querySelector('.dropdown'))
    this.dropdownInfo = {
      classPosition: measures.below < measures.above ? 'is-up' : '',
      availableSpace: Math.max(measures.below, measures.above),
    }
    if (this.dropdownVisible) {
      this.dropdownVisible = false
    } else if (this.items.length > 0) {
      this.dropdownVisible = true
      this.index = null
    }
  }

  _onKeyup(evt) {
    events.handleIndexKeyboardEvent(
      evt, this.index, this.items.length -1,
      {
        onSelect: (idx) => {
          if (!this.items[idx]) {
            return
          }
          else if (this.items[idx] && this.items[idx].href) {
            window.location.href = this.items[idx].href
          }
          else if (this.items[idx]) {
            this._setValue(this.items[idx].value)
          }
        },
        onBlur: () => {this.dropdownVisible = false},
        setIndex: (idx) => {
          this.index = idx
        },
        onKeypress: (event) => {
          this._setValue(event.target.value)
          this._fetchUri(event.target.value)
        },
      },
    )
  }

  _onKeydown(evt) {
    if (evt.key === 'Enter' && this.dropdownVisible) {
      evt.stopPropagation()
      evt.preventDefault()
    }
  }

  _fetchUri = debounce((text) => {
    const url = new URL(this.href, document.location)
    url.searchParams.append('query', text)

    this.loading = true
    fetch(url.toString())
      .then(resp => resp.json())
      .then(items => {
        this.items = items
        this.dropdownVisible = this.items.length > 0
        this.loading = false
        this.index = -1
      })
      .catch(err => {
        console.error(err)
        this.dropdownVisible = false
        this.loading = false
      })
  }, 250)


  _setValue(value) {
    this.value = value
    this.inputRef.value.value = value
    this._internals.setFormValue(value)
    this.dropdownVisible = false
    this.index = null
    this.inputRef.value.focus({preventScroll: true})
  }

  _renderItem(item, index) {
    const className = 'dropdown-item is-clickable' + (
      index === this.index ? ' is-active' : ''
    )
    const body = item.html ? unsafeHTML(item.html) : item.key


    if (item.href) {
      return html`
        <a tabindex="-1" class="${className}" href="${item.href}"> ${body} </a>
        `
    }

    const setValue = () => this._setValue(item.value)
    return html`
      <a tabindex="-1" class="${className}" @click=${setValue}> ${body} </a>
    `
  }

}

