import {LitElement, html} from 'lit'
import events from '../events'
import config from '../config'
import measure from '../measure'


const dateFormats = [
  /(?<year>[\d]{4})[-/](?<month>[\d]{2})[-/](?<day>[\d]{2})/,
  /(?<day>[\d]{2})[-/](?<month>[\d]{2})[-/](?<year>[\d]{4})/,
]

function parseDate(value){
  for (const regexDate of dateFormats) {
    const match = regexDate.exec(value)
    if (match) {
      return [
        parseInt(match.groups.year),
        parseInt(match.groups.month),
        parseInt(match.groups.day),
      ]
    }
  }
}


function getMonthName(year, month) {
  const date = new Date(year, month-1)
  const monthName = date.toLocaleString('default', {month: 'long'})
  return monthName
}

function nextMonth(year, month, day) {
  const date = new Date(year, month-1)
  date.setMonth(date.getMonth() + 1)
  date.setDate(day)
  return [date.getFullYear(), date.getMonth()+1, date.getDate()]
}

function prevMonth(year, month, day) {
  const date = new Date(year, month-1)
  date.setMonth(date.getMonth() - 1)
  date.setDate(day)
  return [date.getFullYear(), date.getMonth()+1, date.getDate()]
}

function makeMonthCalendar(year, month) {
  // trick: last day of current month if day zero of the next month
  const lastDayOfMonth = new Date(year, month, 0).getDate()

  const calendar = []
  let week = []

  // first week of the month may contain preovious month days
  let dayOfWeek = new Date(year, month-1, 1).getDay()
  dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1
  for (let day = 0; day < dayOfWeek; day++) {
    week.push(null)
  }

  for (let dayOfMonth = 1; dayOfMonth <= lastDayOfMonth; dayOfMonth++) {
    week.push(dayOfMonth)
    if (dayOfWeek === 6) {
      calendar.push(week)
      week = []
    }
    dayOfWeek = dayOfWeek < 6 ? dayOfWeek + 1 : 0
  }

  // last week of the month may contain next month days
  if (week.length > 0) {
    for (let day = week.length; day <= 6; day++) {
      week.push(null)
    }
    calendar.push(week)
  }

  return calendar
}


export default class DatePicker extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      placeholder:     {type: String},
      value:           {type: String},
      name:            {type: String},
      class:           {type: String},
      dropdownVisible: {type: Boolean, attribute: false},
      initialized:     {type: Boolean, attribute: false},
      calValue:        {attribute: false},
      dropdownInfo:    {attribute: false},
      kind:            {attribute: false},
      readonly:        {type: Boolean},
      disabled:        {type: Boolean},
    }
  }

  constructor() {
    super()
    this._internals = this.attachInternals()
    this.dropdownVisible = false
    this.initialized = false
    this.dropdownInfo = {}
    this.kind = 'daily'
    this.value = ''

    const today = new Date()
    this.calValue = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
  }

  // register outside click listener
  connectedCallback() {
    super.connectedCallback()
    this._internals.setFormValue(this.value)
    this._listener = document.addEventListener('click', event => {
      const isClicked = event.composedPath().includes(this)
      if (!isClicked) {
        this.dropdownVisible = false
      }
    })
  }

  // remove outside click listener
  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener('click', this._listener)
  }

  updated(changedProps) {
    if (this.name && changedProps.has('value')) {
      if (this.initialized) {
        this._internals.setFormValue(this.value)
        events.trigger('change', this)
      }

      this.initialized = true
    }

    // check if dropdown has been rendered
    if (changedProps.has('dropdownVisible')) {
      if (this.dropdownVisible) {
        const $trigger = this.renderRoot.querySelector('.dropdown-trigger')
        const $dropdown = this.renderRoot.querySelector('.dropdown-menu')
        const measures = measure.getAvailableSpace($trigger)

        const classPosition = (
          measures.below >= $dropdown.offsetHeight
            ? 'is-below'
            : 'is-up'
        )

        if (classPosition !== this.dropdownInfo.classPosition) {
          this.dropdownInfo = {
            ...this.dropdownInfo,
            classPosition,
          }
        }
      }
    }
  }

  isDisabled() {
    return this.disabled || this.closest('fieldset')?.disabled
  }

  render() {
    const inputClass = `input has-text-ellipsed is-fullwidth ${this.class ?? ''}`
    const buttonClass = `button ${this.class ?? ''}`

    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <div class="${this.dropdownVisible ? 'dropdown is-active' : 'dropdown'} ${this.dropdownInfo.classPosition}">
        <div class="dropdown-trigger is-flex is-flex-grow-1">
          <div class="field has-addons">
            <div class="control is-flex is-flex-grow-1">
              <input 
                  role="search"
                  type="text" 
                  class="${inputClass}"
                  ?readonly=${this.readonly}
                  ?disabled=${this.isDisabled()}
                  placeholder="${this.placeholder}"
                  .value="${this.value}"
                  @change="${(evt) => {this.value = evt.target.value}}"
              />
            </div>
            <div class="control">
              <a class="${buttonClass}" @click="${this._toggleDropdown}" ?disabled=${this.isDisabled()}>
                <span class="icon is-small is-right">
                    <i class="far fa-calendar-alt has-text-link"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="dropdown-menu">
          ${
            this.kind === 'daily'
              ? this._renderMonthCalendar(...this.calValue)
              : this._renderYearCalendar(...this.calValue)
          }
        </div>
      </div>
    `
  }

  _toggleDropdown() {
    if (this.readonly || this.isDisabled()) {
      return
    }

    if (this.dropdownVisible) {
      this.dropdownVisible = false
    } else {
      const date = parseDate(this.value)
      if (date) {
        this.calValue = date
      }
      this.dropdownVisible = true
      this.renderRoot.querySelector('input[role="search"]').focus({preventScroll: true})
    }
  }

  _renderYearCalendar(year, month, day) {

    const months = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [10, 11, 12],
    ]

    const prevYear = () => {
      this.calValue = [year-1, month, day]
    }

    const nextYear = () => {
      this.calValue = [year+1, month, day]
    }

    const selectMonth = (selectedMonth) => {
      this.calValue = [year, selectedMonth, day]
      this.kind = 'daily'
    }

    return html`
      <div class="dropdown-content">
        <div class="dropdown-item">
          <div class="field has-addons has-text-centered">
            <div class="control">
              <span class="button" @click="${prevYear}">
                <span class="icon"><i class="fas fa-arrow-left"></i></span>
              </span>
            </div>
            <div class="control is-expanded">
              <span class="button is-flex" @click="${() => {this.kind = 'daily'}}">
                ${year}
              </span>
            </div>
            <div class="control">
              <span class="button" @click="${nextYear}">
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
              </span>
            </div>
          </div>
        </div>
        <div class="dropdown-item">
          <table class="table is-marginless is-size-7-mobile">
            <thead>
            </thead>
            <tbody>
              ${months.map(items => html`
                  <tr>
                    ${items.map(thisMonth => html`
                        <td 
                          class="is-clickable has-text-centered ${thisMonth === month ? 'is-selected' : ''}"
                          @click="${() => selectMonth(thisMonth)}"
                        >
                          ${getMonthName(year, thisMonth)}
                        </td>
                      `,
  )}
                  </tr>
                `,
  )}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    `
  }

  _renderMonthCalendar(year, month, day) {
    const calendar = makeMonthCalendar(year, month)

    const renderWeek = week => {
      return html`
        <tr>
          ${week.map(dayOfWeek => renderDay(dayOfWeek))}
        </tr>`
    }

    const renderDay = dow => {
      return html`
        <td 
          class="${dow === day ? 'is-selected' : ''} has-text-centered is-clickable"
          @click="${() => this._setValue(year, month, dow)}"
        >
          ${dow}
        </td>
      `
    }

    return html`
      <div class="dropdown-content">
        <div class="dropdown-item">
          <div class="field has-addons has-text-centered">
            <div class="control">
              <span 
                class="button"
                @click="${() => {this.calValue = prevMonth(year, month, day)}}"
              >
                <span class="icon"><i class="fas fa-arrow-left"></i></span>
              </span>
            </div>
            <div class="control is-expanded">
              <span class="button is-flex" @click="${() => {this.kind = 'monthly'}}">
                ${getMonthName(year, month, day)} ${year}
              </span>
            </div>
            <div class="control">
              <span 
                class="button"
                @click="${() => {this.calValue = nextMonth(year, month, day)}}"
              >
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
              </span>
            </div>
          </div>
        </div>
        <div class="dropdown-item">
          <table class="table is-marginless is-size-7-mobile">
            <thead>
              <tr>
                <th>Lu</th>
                <th>Ma</th>
                <th>Me</th>
                <th>Gi</th>
                <th>Ve</th>
                <th class="has-text-info">Sa</th>
                <th class="has-text-info">Do</th>
              </tr>
            </thead>
            <tbody>
              ${calendar.map(week => renderWeek(week))}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    `
  }

  _setValue(year, month, day) {
    this.calValue = [year, month, day]
    this.value = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    this.dropdownVisible = false
    this.index = -1
    this.renderRoot.querySelector('input[role="search"]').focus({preventScroll: true})
  }

}

