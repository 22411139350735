// @ts-check
import {unsafeHTML} from 'lit/directives/unsafe-html.js'

/**
 * @typedef {{html: (import("lit/directive").DirectiveResult<typeof import("lit/directives/unsafe-html").UnsafeHTMLDirective>|string), text: string, value: string}} JSONOption
 * @typedef {{label: string, options: (JSONOptGroup | JSONOption)[]}} JSONOptGroup
 */

/**
 * @deprecated
 */
function normalizeJsonElement(elem) {
  if (elem.options) {
    return {
      label: elem.label || '',
      options: normalizeJSON(elem.options),
    }
  }
  return {
    html: elem.html || elem.value || elem,
    value: elem.value || elem,
  }
}

/**
 * @deprecated
 */
function normalizeJSON(elems) {
  return elems.map(normalizeJsonElement)
}


/**
 * @deprecated
 */
function parseHTMLElement(options) {
  const filterOptions = elem => ['OPTION', 'OPTGROUP'].includes(elem.tagName)
  const selectedItems = new Set()
  function parseOptgroup(opt) {
    if (opt.tagName === 'OPTION') {
      const tag = {
        html: opt.innerHTML,
        value: opt.value ?? opt.text,
      }

      if (opt.selected) {
        selectedItems.add(tag)
      }
      return tag
    }

    return {
      label: opt.label,
      options: Array.from(opt.children).filter(filterOptions).map(parseOptgroup),
    }
  }

  return {
    options: options.filter(filterOptions).map(parseOptgroup),
    selected: selectedItems,
  }
}


/**
 * @typedef {{
 *  html?: string,
 *  text?: string,
 *  value: string,
 * }} FetchedOption
 *
 *
 * @param {FetchedOption} o
 * @returns {JSONOption}
 */
function normalizeOption(o) {
  return {
    value: o.value ?? o.text ?? '',
    text: o.text ?? o.value ?? '',
    html: o.html ? unsafeHTML(o.html) : (o.text ?? o.value ?? unsafeHTML('&nbsp;')),
  }
}

/**
 * @typedef {{
 *  label: string,
 *  options: (FetchedOption|FetchedGroup)[],
 * }} FetchedGroup
 */

/**
 * @param {(FetchedOption|FetchedGroup)[]} options
 * @returns {(JSONOptGroup|JSONOption)[]}
 */
function normalizeOptOrGroup(options) {
  const newOptions = []
  for (const opt of options) {
    if ('options' in opt) {
      newOptions.push({
        label: opt.label,
        options: normalizeOptOrGroup(opt.options),
      })
    }
    else {
      newOptions.push(normalizeOption(opt))
    }
  }
  return newOptions
}


/**
 * @param {Element[]} options
 * @returns {{elements: (JSONOptGroup | JSONOption)[], selected: Set<JSONOption>}}
 */
function parseSlottedOptions(options) {

  /**
   * @param {Element} elem
   * @returns {elem is (HTMLOptionElement | HTMLOptGroupElement)}
   */
  const filterOptions = elem => elem instanceof HTMLOptionElement || elem instanceof HTMLOptGroupElement

  /** @type {Set<JSONOption>} */
  const selectedItem = new Set()

  /**
   * @param {HTMLOptionElement | HTMLOptGroupElement} opt
   * @returns {(JSONOption | JSONOptGroup)}
   */
  function parseOptgroup(opt) {
    if (opt instanceof HTMLOptionElement) {
      const item = {
        value: opt.value === undefined ? opt.text : opt.value,
        text: opt.text,
        html: opt.text,
      }

      if (opt.selected) {
        selectedItem.add(item)
      }
      return item
    }

    // OPTGROUP
    return {
      label: opt.label,
      options: Array.from(opt.children).filter(filterOptions).map(parseOptgroup),
    }
  }

  return {
    elements: options.filter(filterOptions).map(parseOptgroup),
    selected: selectedItem,
  }
}

/**'
 * @param {(JSONOption|JSONOptGroup)} o
 * @returns {o is JSONOption}
 */
function isJSONOption(o) {
  return 'value' in o
}

/**'
 * @param {(JSONOption|JSONOptGroup)} o
 * @returns {o is JSONOptGroup}
 */
function isJSONOptGroup(o) {
  return 'options' in o
}


/**
 * @param {(JSONOptGroup | JSONOption)[]} options
 * @param {string | RegExp} query
 * @return {(JSONOptGroup | JSONOption)[]}
 */
function filterParsedOptions(options, query) {
  if (!query) {
    return options
  }

  const re = (query instanceof RegExp) ? query : new RegExp(query, 'i')

  const newOptions = []

  for (const opt of options) {
    if (isJSONOption(opt)) {
      if (re.test(opt.text)) {
        newOptions.push(opt)
      }
      continue
    }

    // for groups
    const filteredOptions = filterParsedOptions(opt.options, re)
    if (!filteredOptions.length) {
      continue
    }

    newOptions.push({
      label: opt.label,
      options: filteredOptions,
    })
  }
  return newOptions
}


/**
 * @param {(JSONOption | JSONOptGroup)[]} items
 * @yields {JSONOption}
 */
function* iterOptions(items) {
  for (const item of items) {
    if (isJSONOption(item)) {
      yield item
    }
    else {
      yield* iterOptions(item.options)
    }
  }
}

export default {
  parseHTMLElement,
  normalizeJSON,
  normalizeOption,
  normalizeOptOrGroup,
  parseSlottedOptions,
  isJSONOption,
  isJSONOptGroup,
  filterParsedOptions,
  iterOptions,
}
