import {LitElement, html, css} from 'lit'
import {ref, createRef} from 'lit/directives/ref.js'
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import {classMap} from 'lit/directives/class-map.js'
import {styleMap} from 'lit/directives/style-map.js'
import debounce from 'lodash/debounce'
import config from '../config'
import events from '../events'
import measure from '../measure'
import options from '../options'


function extractHtmlText(html) {
  const tmpSpan = document.createElement('span')
  tmpSpan.innerHTML = html
  return tmpSpan.textContent
}

const fetchTagsDebounced = debounce(
  async (href, query, resolve, reject) => {
    try {
      const url = new URL(href, document.location)
      url.searchParams.append('query', query)

      const resp = await fetch(url.toString())
      const tagData = await resp.json()

      resolve(options.normalizeJSON(tagData))
    } catch (e) {
      reject(e)
    }
  }, 250,
)

const fetchTags = (href, query) => new Promise(
  (resolve, reject) => fetchTagsDebounced(href, query, resolve, reject),
)

export default class TagSelector extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      placeholder: {type: String},
      href: {type: String},
      name: {type: String},
      disabled: {type: Boolean, attribute: true},
      class: {type: String},

      // user input text
      query: {type: String, attribute: false},

      dropdownVisible: {type: Boolean, attribute: false},
      items: {attribute: false},

      // value of the form
      value: {type: String, attribute: false},
      loading: {type: Boolean, attribute: false},
      dropdownInfo: {attribute: false},
      tags: {attribute: false},
      repr: {attribute: false},
      dropdownIndex: {attribute: false, type: Number},
      editable: {type: Boolean},
    }
  }

  constructor() {
    super()
    this._internals = this.attachInternals()

    // list of selected tags
    this.tags = []

    // list of available tags
    this.availableTags = []

    // list of dropdown-visible tags
    this.items = []

    // selected dropdown element
    this.dropdownIndex = null
    this.dropdownVisible = false

    // how and where to draw dropdown for tag selection
    this.dropdownInfo = {}


    this.query = ''
    this.loading = false
    this.value = ''
    this.href = null
    this.editable = false

    this.searchRef = createRef()
    this.dropdownRef = createRef()
    this.addItemRef = createRef()
  }

  static styles = css`
    div.dropdown-item.is-clickable:hover {
      background-color: #f5f5f5;
    }
    div.dropdown-item.is-clickable.is-active {
      background-color: #485fc7;
      color: #fff;
    }
    .nowrap {
      white-space: nowrap;
    }
  `

  // register outside click listener
  connectedCallback() {
    super.connectedCallback()

    this.listeners = {
      onclickoutside: evt => {
        const isClicked = (
          evt.composedPath().includes(this.dropdownRef.value)
          ||
          evt.composedPath().includes(this.addItemRef.value)
        )
        if (!isClicked) {
          this.dropdownVisible = false
        }
      },
      onslotchange: () => this._onSlotChange(),
    }

    document.addEventListener('click', this.listeners.onclickoutside)
    this.shadowRoot.addEventListener('slotchange', this.listeners.onslotchange)
  }

  // remove outside click listener
  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener('click', this.listeners.onclickoutside)
    this.shadowRoot.removeEventListener('slotchange', this.listeners.onslotchange)
  }

  _onSlotChange() {
    this.popSlottedValues()
    this.updateForm(false)
  }

  updated(changedProps) {
    if (changedProps.has('dropdownVisible')) {
      if (this.dropdownVisible) {
        // dropdown opened
        const elem = this.renderRoot.querySelector('input[role="search"]')
        elem.focus()

        // reset index for keyboard selection
        this.dropdownIndex = null
        this._filterOrFetch(this.searchRef.value?.value || '')

      } else {
        // when dropdown is closed update the values of tags
        this.updateForm()
      }
    }
  }

  updateForm(triggerChange = true) {
    const values = this.tags.map(getTagValue)

    const formData = new FormData()
    for (const v of values) {
      formData.append(this.name, v)
    }
    this._internals.setFormValue(formData)

    const newValue = values.join(', ')
    if (triggerChange && newValue !== this.value) {
      events.trigger('change', this)
    }
    this.value = newValue
  }


  _deleteTag(selected) {
    this.tags = this.tags.filter(tag => tag !== selected)
  }

  _addTag(tag) {
    this.tags = [...this.tags, tag]
  }

  _renderTag(tag) {
    let tagText = ''
    if (tag.label){
      tagText = document.createTextNode(tag.label)
    } else {
      tagText = tag.html ? extractHtmlText(tag.html) : tag
    }
    if (this.disabled) {
      return html`
        <div class="control">
          <span class="tag ${this.class}"> ${tagText} </span>
        </div>
      `
    }
    const onDelete = () => {
      this._deleteTag(tag)
      this.updateForm()
    }

    return html`
      <div class="control">
        <span class="tags has-addons">
          <span class="tag ${this.class}"> ${tagText} </span>
          <a class="tag is-delete ${this.class}"
            @click="${onDelete}"
            data-testid="del-tags">
          </a>
        </span>
      </div>
    `
  }
  render() {
    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <slot style="display: none"></slot>

      <div
        class="${this.dropdownVisible ? 'dropdown is-active' : 'dropdown'} ${this.dropdownInfo.classPosition}">
        <div class="dropdown-trigger is-flex is-flex-grow-1">
          <div data-testid="tags-container" class="field is-grouped is-grouped-multiline">
          ${this.tags.map(tag => this._renderTag(tag))}
          ${
            (() => {
              if (this.disabled) {
                return html``
              }
              return html`
                <div class="control">
                  <div class="tags ">
                    <div
                      ${ref(this.addItemRef)}
                      class="tag is-success is-clickable ${this.class}" data-testid="add-tags" @click="${this._toggleDropdown}" style="width: 2em">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
              `
            })()
          }
          </div>
        </div>
        <div class="dropdown-menu" ${ref(this.dropdownRef)}>
          ${this.renderSearch()}
        </div>
      </div>
    `
  }

  renderSearch() {
    const selectedTags = new Set(this.tags.map(({value}) => value))
    return html`
      <div class="dropdown-content py-0">
        <div class="dropdown-item p-2">
          <div class="field has-addons">
          <div class="control has-icons-right is-flex is-flex-grow-1">
              <input 
                role="search"
                data-testid="search-tag"
                type="text" class="input is-fullwidth"
                placeholder=${this.placeholder}
                .value=${this.query}
                ${ref(this.searchRef)}
                @keyup=${evt => this._onKeyup(evt)}
              />
              <span class="icon is-right">
                <i class=${this.loading ? 'fas fa-circle-notch fa-spin' : 'fa fa-search'}></i>
              </span>
            </div>
          </div>
        </div>
        <div style="overflow-y: scroll; max-height: ${this.dropdownInfo.availableSpace}px" id="items">
        ${
          this.items
            .flatMap(t => t.options ? [t, ...t.options] : t)
            .map((item, index) =>
              this._renderItem(
                item,
                index,
                selectedTags,
              ))
        }
        </div>
      </div>
      `
  }

  /**
   * Transform <option/> in list of available tags ({html: '', value: ''})
   */
  popSlottedValues() {
    const slot = this.renderRoot.querySelector('slot')
    if (!slot) {
      return
    }

    const elements = slot.assignedElements()

    const parsed = options.parseHTMLElement(elements)

    this.items = parsed.options
    this.availableTags = parsed.options
    this.tags = [...parsed.selected].sort()
  }

  _toggleDropdown() {
    const measures = measure.getAvailableSpace(this.shadowRoot.querySelector('.dropdown'))
    this.dropdownInfo = {
      classPosition: measures.below < measures.above ? 'is-up' : '',
      availableSpace: Math.max(measures.below, measures.above),
    }

    if (this.dropdownVisible) {
      this.dropdownVisible = false
    } else {
      this.dropdownVisible = true
      this.dropdownIndex = null
    }
  }

  _onKeyup(evt) {
    this.query = evt.target.value
    events.handleIndexKeyboardEvent(
      evt, this.dropdownIndex, this.items.length - 1,
      {
        onSelect: (idx) => {
          const tag = this.items[idx]
          if (tag) {
            if (this.tags.includes(tag)) {
              this._deleteTag(tag)
            } else {
              this._addTag(tag)
            }
          } else if (this.editable && this.query){
            const value = this.query.trim()
            if (value) {
              const editableTag = {
                'label': value,
                'value': value,
              }
              if (!this.tags.some(e => e.value === value) && !this.availableTags.some(e => e.value === value)){
                this._addTag(editableTag)
                this.availableTags = [editableTag, ...this.availableTags]
                this._filterOrFetch(value)
              }
            } else {
              this.query = ''
            }
          }
        },
        onBlur: () => {
          this.dropdownVisible = false
        },
        setIndex: (idx) => { this.dropdownIndex = idx },
        onKeypress: (event) => this._filterOrFetch(event.target.value),
      },
    )
  }

  _filterOrFetch(text) {
    if (this.href) {
      this._fetchUri(text)
    } else {
      const regexp = new RegExp(text, 'i')
      this.items = filterOptions(this.availableTags, regexp)
    }
  }

  _fetchUri(text) {
    this.loading = true
    fetchTags(this.href, text)
      .then(tags => {
        this.availableTags = tags
        this.items = tags
        this.dropdownVisible = true
        this.loading = false
      })
      .catch(err => {
        console.error(err)
        this.dropdownVisible = true
        this.loading = false
      })
  }

  _renderItem(tag, index, selectedTags) {
    const selected = selectedTags.has(tag.value)
    if (tag.options) {
      const onClick = () => {
        for (const opt of tag.originalGroup.options) {
          if (!selectedTags.has(getTagValue(opt))) {
            this._addTag(opt)
          }

          const input = this.searchRef.value
          if (input) {
            input.focus()
            input.setSelectionRange(0, input.value.length)
          }
        }
      }
      return html`
        <hr class="dropdown-divider">
        <div tabindex="-1" class="dropdown-item nowrap is-clickable has-text-weight-semibold" @click=${onClick}>
          ${
          tag.html
            ? unsafeHTML(tag.html)
            : tag.label
          }
        </div>`
    }

    const addOnClick = () => {
      if (selected) {
        return
      }
      this._addTag(tag)
      const input = this.searchRef.value
      if (input) {
        input.focus()
        input.setSelectionRange(0, input.value.length)
      }
    }

    const elemClassMap = {
      'dropdown-item ': true,
      'nowrap': true,
      'is-clickable': true,
      'is-active': index === this.dropdownIndex,
      'has-text-grey-light': selected,
    }

    const elemStyleMap = {
      cursor: selected ? 'not-allowed !important' : 'pointer',
    }

    let value = ''
    if (tag.label){
      value = tag.label
    } else {
      value = tag.html ? unsafeHTML(tag.html) : tag
    }
    return html`
      <div
          @click="${addOnClick}"
          tabindex="-1"
          data-testid="add-value"
          style="${styleMap(elemStyleMap)}"
          class=${classMap(elemClassMap)}>
        ${value}
      </div>
    `
  }
}

function getTagValue(tag) {
  return tag.value
}

function filterOptions(options, reg, tmpSpan=undefined) {
  // filter dropdown items by text content
  if (!tmpSpan) {
    tmpSpan = document.createElement('span')
  }

  const newOptions = []

  for (const opt of options) {
    if (opt.options) {
      const fOptions = filterOptions(opt.options, reg, tmpSpan)
      if (fOptions.length >= 1) {
        newOptions.push({...opt, options: fOptions, originalGroup: opt})
      }
    } else {
      tmpSpan.innerHTML = opt.html
      if (reg.test(tmpSpan.textContent)) {
        newOptions.push(opt)
      }
    }
  }

  return newOptions
}
